<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="i18nTranslatesList.items"
		class="elevation-1"
		:footer-props="{
			itemsPerPageAllText: 'Все',
			itemsPerPageText: 'Строк на стр.'
		}"
		loading-text="Загрузка данных"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Переводы сущностей справочников</v-toolbar-title>
				<v-spacer/>
				<v-autocomplete
					outlined
					hide-details
					clearable
					dense
					no-data-text="Нет данных"
					:items="i18nEntityTypesList.items"
					item-text="name"
					item-value="id"
					:menu-props="{ top: false, offsetY: true }"
					label="Справочник"
					@change="changeEntityType"
				/>
				<v-dialog
					v-model="dialogEdit"
					max-width="500px"
				>
					<v-card>
						<v-card-title>{{ formTitle }}</v-card-title>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col
										cols="12"
										sm="12"
										md="12"
									>
										<v-text-field
											v-model="editedItem.translate"
											label="Перевод"
											outlined
											hide-details="auto"
										/>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="grey darken-1"
								text
								@click="resetEditedItem"
							>
								Отменить
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="saveItem"
							>
								Сохранить
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title>Точно удалить?</v-card-title>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="grey darken-1" text @click="resetEditedItem">Отмена</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer/>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
<!--			<v-toolbar-->
<!--				flat-->
<!--			>-->
<!--				<v-row align="center">-->
<!--					<v-col cols="6">-->
<!--						<v-select-->
<!--							:items="i18nEntityTypesList.items"-->
<!--							item-text="name"-->
<!--							item-value="id"-->
<!--							:menu-props="{ top: false, offsetY: true }"-->
<!--							label="Справочник"-->
<!--							@change="changeEntityType"-->
<!--						></v-select>-->
<!--					</v-col>-->
<!--				</v-row>-->
<!--			</v-toolbar>-->
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn
				icon
				@click="editItem(item)"
				small
			>
				<v-icon small>mdi-pencil</v-icon>
			</v-btn>
			<v-btn
				icon
				small
				@click="deleteItem(item)"
			>
				<v-icon small>mdi-delete</v-icon>
			</v-btn>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="initialize"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	data: function () {
		return {
			title: 'Переводы сущностей справочников',
			loading: true,
			dialogEdit: false,
			dialogDelete: false,
			headers: [
				{
					text: 'ID сущности',
					align: 'start',
					sortable: false,
					value: 'entity_id'
				},
				{
					text: 'Имя',
					sortable: true,
					value: 'entity_name'
				},
				{
					text: 'Перевод',
					sortable: true,
					value: 'translate'
				},
				{
					text: 'Язык',
					sortable: true,
					value: 'lang_name'
				},
				{
					text: 'Действия',
					value: 'actions',
					sortable: false
				}
			],
			editedIndex: -1,
			editedItem: {
				translate: ''
			},

			defaultItem: {
				translate: ''
			}
		}
	},

	computed: {
		...mapGetters({
			i18nEntityTypesList: 'i18n/i18nEntityTypesList',
			i18nTranslatesList: 'i18n/i18nTranslatesList'
		}),
		formTitle () {
			return 'Редактировать перевод'
		}
	},
	watch: {
		dialogEdit (val) {
			if (!val) {
				this.resetEditedItem()
			}
		},

		dialogDelete (val) {
			if (!val) {
				this.resetEditedItem()
			}
		}
	},
	created () {
		this.initialize()
		this.initEntityTypes()
	},

	methods: {
		...mapActions({
			getTranslateEntityTypesList: 'i18n/getTranslateEntityTypesList',
			getTranslatesList: 'i18n/getTranslatesList',
			deleteTranslatesList: 'i18n/deleteTranslatesList',
			putTranslatesList: 'i18n/putTranslatesList',
			postTranslatesList: 'i18n/postTranslatesList'
		}),

		async initialize () {
			this.loading = true
			try {
				await this.getTranslatesList()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},

		async initEntityTypes () {
			this.loading = true

			try {
				await this.getTranslateEntityTypesList()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},

		async changeEntityType (type_id) {
			this.loading = true
			await this.getTranslatesList({ type_id: type_id })
			this.loading = false
		},

		deleteItem (item) {
			this.$set(this, 'editedIndex', this.i18nTranslatesList.items.indexOf(item))
			this.$set(this, 'editedItem', { ...item })
			this.$set(this, 'dialogDelete', true)
		},

		editItem (item) {
			this.$set(this, 'editedIndex', this.i18nTranslatesList.items.indexOf(item))
			this.$set(this, 'editedItem', { ...item })
			this.$set(this, 'dialogEdit', true)
		},

		/* Очистка данных диалоговых окон */
		resetEditedItem () {
			this.$nextTick(() => {
				this.$set(this, 'editedIndex', -1)
				this.$set(this, 'editedItem', this.defaultItem)
				this.$set(this, 'dialogDelete', false)
				this.$set(this, 'dialogEdit', false)
			})
		},

		deleteItemConfirm () {
			this.deleteTranslatesList(this.editedItem).then(
				() => {
					this.$notify({ type: 'info', title: 'Перевод удален' })
					this.resetEditedItem()
				},
				(error) => {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			)
		},

		saveItem () {
			const action = this.editedItem.id ? 'putTranslatesList' : 'postTranslatesList'
			try {
				this[action](
					this.editedItem
				).then(
					() => {
						this.$notify({ type: 'info', title: this.editedItem.id ? 'Изменения сохранены' : 'Перевод создан' })
						this.getTranslatesList({ type_id: this.editedItem.entity_type_id })
						this.resetEditedItem()
					}, (error) => {
						this.$notify({
							type: 'error',
							title: error
						})
					}
				)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
			this.loading = false
		}
	}
}
</script>
